/* Utilities */
@import '@tokenyico/tokeny-ui/src/utils/reset/reset.less';
@import '@tokenyico/tokeny-ui/src/utils/shadows/shadows.less';
@import '@tokenyico/tokeny-ui/src/utils/type-scale/type-scale.less';

/* Content */
@import '@tokenyico/tokeny-ui/src/design-system/typography/typography.less';
@import '@tokenyico/tokeny-ui/src/design-system/icons/icons.less';
@import '@tokenyico/tokeny-ui/src/design-system/spacing/spacing.less';

/* Defaults */
@import '@tokenyico/tokeny-ui/src/design-system/borders/borders.less';
@import '@tokenyico/tokeny-ui/src/design-system/units/units.less';
@import '@tokenyico/tokeny-ui/src/design-system/grid/grid.less';
@import '@tokenyico/tokeny-ui/src/design-system/backgrounds/backgrounds.less';

/* Layouts */
@import '@tokenyico/tokeny-ui/src/patterns/Navigation/navigation.less';
@import '@tokenyico/tokeny-ui/src/patterns/Page/page.less';
@import '@tokenyico/tokeny-ui/src/patterns/Footer/footer.less';
@import '@tokenyico/tokeny-ui/src/patterns/Auth/auth.less';

/* Modifiers */
@import '@tokenyico/tokeny-ui/src/modifiers/alignment/alignment.less';
@import '@tokenyico/tokeny-ui/src/modifiers/content/content.less';
@import '@tokenyico/tokeny-ui/src/modifiers/grid/grid.less';
@import '@tokenyico/tokeny-ui/src/modifiers/images/images.less';
@import '@tokenyico/tokeny-ui/src/modifiers/misc/misc.less';
@import '@tokenyico/tokeny-ui/src/modifiers/shadows/shadows.less';
@import '@tokenyico/tokeny-ui/src/modifiers/spacing/spacing.less';
@import '@tokenyico/tokeny-ui/src/modifiers/text/text.less';

/* Elements */
@import '@tokenyico/tokeny-ui/src/components/Avatar/avatar.less';
@import '@tokenyico/tokeny-ui/src/components/BadgeTag/badge-tag.less';
@import '@tokenyico/tokeny-ui/src/components/Breadcrumbs/breadcrumbs.less';
@import '@tokenyico/tokeny-ui/src/components/Button/buttons.less';
@import '@tokenyico/tokeny-ui/src/components/Card/cards.less';
@import '@tokenyico/tokeny-ui/src/components/Dropdown/dropdown.less';
@import '@tokenyico/tokeny-ui/src/components/Drawer/drawer.less';
@import '@tokenyico/tokeny-ui/src/components/Form/index.less';
@import '@tokenyico/tokeny-ui/src/components/LanguageMenu/language-menu.less';
@import '@tokenyico/tokeny-ui/src/components/Link/link.less';
@import '@tokenyico/tokeny-ui/src/components/Logo/logo.less';
@import '@tokenyico/tokeny-ui/src/components/OnchainId/onchainid.less';
@import '@tokenyico/tokeny-ui/src/components/Pill/pill.less';
@import '@tokenyico/tokeny-ui/src/components/Slider/slider.less';
@import '@tokenyico/tokeny-ui/src/components/Modal/modal.less';
@import '@tokenyico/tokeny-ui/src/components/Toast/toast.less';
// @import '@tokenyico/tokeny-ui/src/components/TokenSelector/token-selector.less';
@import '@tokenyico/tokeny-ui/src/components/Tooltip/tooltip.less';

/* React Components */
@import '@tokenyico/tokeny-ui/src/components/Chart/Chart.less';
@import '@tokenyico/tokeny-ui/src/components/Loader/Loader.less';
@import '@tokenyico/tokeny-ui/src/components/NavItem/NavItem.less';
@import '@tokenyico/tokeny-ui/src/components/ProfileProgress/ProfileProgress.less';
@import '@tokenyico/tokeny-ui/src/components/Progress/Progress.less';
@import '@tokenyico/tokeny-ui/src/components/Stats/Stats.less';
@import '@tokenyico/tokeny-ui/src/patterns/Tabs/Tabs.less';
@import '@tokenyico/tokeny-ui/src/components/UserMenu/UserMenu.less';

/* React Web3 Components */
@import '@tokenyico/tokeny-ui/src/components/EthWallet/ethwallet.less';
// Use this file to overwrite the tokeny design system css
// overwrite global CSS vars here e.g var(--gw)
// This file should be reviewed regularly to see what styles we can port over to the design system.
@import '@tokenyico/design-system/dist/design-system/less/breakpoints.less';
@import '@tokenyico/design-system/dist/design-system/less/flex.less';
@import '@tokenyico/design-system/dist/design-system/less/typography.less';
@import '@tokenyico/design-system/dist/design-system/less/shadows.less';
@import '@tokenyico/design-system/dist/design-system/less/colors.less';

/**
** Client theme variables:
** JSON theme data loaded in index.jsx
**/
:root {
  --primary: #2caf51;
  --primary-darken: #1b8238;
}

/**
** Globals
**/

html,
body,
#root,
.loading {
  height: 100%;
  min-height: 100vh;
}

body {
  background-color: var(--grey-300);
  max-width: 100vw;
}

.app {
  height: 100vh;
  overflow: auto;
}

* {
  font-family: var(--font-family);
}

/**
** Layout
**/

.banner {
  background-color: var(--black);
  position: absolute;
  background-image: url('./assets/themes/default/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  height: 30vh;
  width: 100%;
  z-index: -1;
  margin-top: 3rem;
}

.app-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0rem;
  max-width: 100vw;
  height: fit-content;
  .md({
    margin-top: 3rem;
  });
}

/**
 * Header
 */
.app > header {
  z-index: 7000;
  background-color: var(--white);
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  img {
    max-width: auto;
    max-height: 1.8rem;
  }

  ds-user-menu {
    .menu-container {
      right: -8px !important;
    }
    .menu-container a {
      color: var(--grey-900);
    }
    .menu-container a:hover {
      text-decoration: none;
      color: var(--black);
    }
  }

  nav {
    display: inline-flex;
    overflow-x: hidden;
    overflow-x: -moz-hidden-unscrollable;
    overflow-x: clip;

    &::-webkit-scrollbar {
      height: 0;
    }

    ul {
      list-style: none;

      display: grid;
      grid-gap: 0.2rem;
      grid-template-columns: repeat(1, auto);

      .sm({
          grid-template-columns: repeat(2, auto);
        });

      .md({
          grid-template-columns: repeat(3, auto);
        });

      .lg({
          grid-template-columns: repeat(6, auto);
        });
    }

    li {
      display: flex;
      align-items: center;
      vertical-align: bottom;
      &.disabled {
        a {
          cursor: not-allowed;
          color: var(--coolGrey-400);
          &:hover {
            color: var(--coolGrey-400);
            ds-icon {
              color: var(--coolGrey-400);
              fill: var(--coolGrey-400);
            }
          }
        }
      }
    }

    ds-icon {
      vertical-align: middle;
      margin-right: 0.4rem;
      margin-bottom: 0.128rem;
    }

    ds-badge {
      margin-bottom: 0.128rem;
    }

    a {
      color: var(--black);
      display: block;
      padding: 0.8rem 1.2rem 0.6rem;
      white-space: nowrap;
      text-decoration: none;
      transition: color 0.25s ease-in;

      &:hover {
        text-decoration: none;
        color: var(--primary);
        transition: color 0.25s ease-out;

        ds-icon {
          color: var(--primary);
          fill: var(--primary);
          transition: all 0.25s ease-out;
        }
      }
    }
    a.active {
      font-weight: 500;
      color: var(--primary) !important;
      transition: color 0.25s ease-out;

      ds-icon {
        color: var(--primary) !important;
        fill: var(--primary) !important;
        transition: all 0.25s ease-out;
      }
    }
  }

  .token-selector__menu {
    position: fixed;
    top: 3rem;
    left: calc(100vw - 25.5rem);
    z-index: 8888;

    .xs-only({
      width: 100vw;
      right: 0;
    });
  }
}

/* Page */

.page {
  width: 100%;
  max-width: 100rem;
  margin-top: 1rem;
  margin-bottom: 0;

  .md({
    margin-top: calc(var(--gw) * 1.75);
  });

  .page__view {
    position: relative;
    padding: var(--gw) calc(var(--gw) * 3);
  }
  &__sidebar {
    &__header {
      ._text\:reg;
      padding-left: var(--gw);
      padding-right: var(--gw);
      margin-bottom: calc(var(--gw) * 1.5);
    }
  }
  @media screen and (max-width: 700px) {
    padding-left: calc(var(--gw) / 2);
    padding-right: calc(var(--gw) / 2);
    .page__view {
      padding: var(--gw);
    }
  }
}

@media screen and (max-width: 50rem) {
  .page {
    padding: 0.25rem !important;
    .card,
    .card--block {
      margin: 0;
    }
    &__view {
      width: 100% !important;
      border: none;
    }
    &__sidebar {
      width: 100%;
      &__header {
        display: none;
      }
      &__menu {
        ul li {
          display: inline-flex;
        }
      }
    }
  }
}

/* hack for table component */
a + .btn {
  margin-left: calc(var(--gw) / 2);
}

.basic-info {
  &__title {
    color: var(--text-secondary);
    ._text\:reg;
    + div {
      min-height: 1rem;
    }
  }

  [class^='col-'] {
    display: inline-grid;
  }
}

.token-logo {
  margin-right: calc(var(--gw));
  margin-top: calc(var(--gw) / 2);
  height: 16px;
}

.Toastify {
  &__toast {
    background-color: white !important;
    &-body {
      margin: 0 0 0 1rem !important;
    }
  }
}
strong {
  svg {
    vertical-align: top !important;
  }
}

.table__container .table {
  min-height: 9rem !important;
  margin-bottom: 0 !important;
  td strong {
    display: block;
    margin-bottom: 0.25rem;
    color: var(--text-secondary);
    font-weight: 400;
    svg {
      vertical-align: baseline;
      margin-top: 0.25rem;
      fill: var(--grey-500);
    }
  }
}

.table thead th {
  color: var(--grey-900);
  &:hover {
    color: var(--black);
  }
}

/* investor profile */

.profile-view {
  margin-top: -1rem;
}

.investor-details {
  margin: 0 0 2rem 0;
  padding: 0;
  list-style: none;

  &__title {
    min-width: 8rem;
    color: var(--grey-900);
    white-space: pre;
    margin-right: var(--gw);
    flex: 1;
  }

  &__title + div {
    flex: 3;
  }

  &__group-information {
    display: flex;
    ._text\:sm;
    padding: calc(var(--gw) * 0.8) 0;
    border-bottom: 1px solid var(--grey-400);
  }

  .select__header {
    color: black;
    padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    height: 2rem;
  }

  .select__input {
    width: 100% !important;
  }

  .select::after {
    padding: 0.5em;
  }

  .DateInput_input {
    color: black;
  }

  .form-group {
    margin-bottom: 0;
  }

  .react-date-picker__wrapper {
    border-width: 1px;
    border-style: solid;
    border-radius: var(--radius);
    border-color: var(--grey-500);
  }
  .react-date-picker__inputGroup__input {
    height: auto;
  }
  .react-date-picker__inputGroup {
    padding: 0rem 0.25rem 0rem 0.5rem;
  }
  .react-calendar__month-view__days__day--weekend {
    color: black;
  }
  .react-date-picker__button:enabled {
    background-color: var(--bg-grey-light);
  }
}

/* Messages view */

@messagingMobile: 70rem;

.messages {
  width: 100%;
  max-width: 85rem;
  margin: 0 auto;
  padding-left: 3rem;
  padding-right: 3rem;
  @media screen and (max-width: 65rem) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    background: var(--grey-300);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--grey-500);
    border-radius: 200px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--grey-500);
  }

  .inbox-toggle {
    display: none;
    cursor: pointer;

    @media screen and (max-width: @messagingMobile) {
      display: block;
      ._flex\:center;
    }
  }

  &__body {
    display: flex;
    width: 100%;
    min-height: 66vh;
    overflow: hidden;

    @media screen and (max-width: @messagingMobile) {
      width: 100%;
      position: relative;
    }
  }

  &__list {
    flex: 1;
    min-width: 33rem;
    border-bottom-left-radius: var(--radius);
    border-right: 1px solid var(--grey-300);
    max-height: 66vh;
    overflow-y: scroll;

    @media screen and (max-width: 70rem) {
      min-width: 20rem;
    }

    @media screen and (max-width: @messagingMobile) {
      width: 20rem;
      position: absolute;
      left: -100vw;
      transition: left 0.25s ease;
    }

    @media screen and (max-width: 55rem) {
      min-width: unset;
    }

    ul li {
      width: 100%;
      cursor: pointer;
      transition: all 75ms ease-in;
      padding: calc(var(--gw) * 1.2) calc(var(--gw) * 1.5) calc(var(--gw) * 1.2)
        calc(var(--gw) * 1.5);
      &:hover {
        opacity: 1 !important;
      }
      .message__right {
        @media screen and (max-width: 70rem) {
          display: none;
        }
      }

      .message__center {
        width: 15rem;
        align-self: center;
        @media screen and (max-width: 60rem) {
          width: 13rem;
        }
        @media screen and (max-width: 40rem) {
          width: 8rem;
        }
      }
    }
  }

  &__detail {
    flex: 1.5;
    max-height: 66vh;
    overflow-y: scroll;
    p {
      line-height: 1.5rem;
    }

    @media screen and (max-width: @messagingMobile) {
      width: 100%;
      position: absolute;
      right: 0;
      transition: right 0.25s ease;
    }
  }

  &__list.show-messages {
    @media screen and (max-width: @messagingMobile) {
      left: 0;
      transition: left 0.25s ease;
    }
  }

  &__detail.show-messages {
    @media screen and (max-width: @messagingMobile) {
      right: -20rem;
      transition: right 0.25s ease;
    }
  }
}

/* Empty state */

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 23rem;
  text-align: center;
  padding: calc(var(--gw) * 4) 0;

  &__icon {
    color: var(--grey-700);
    margin-bottom: calc(var(--gw));
    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &__title {
    margin-bottom: calc(var(--gw) / 1.5);
    font-size: 1.1rem;
    line-height: 1.3;
  }

  &__body {
    ._text\:sm;
    color: var(--grey-900);
    margin-bottom: var(--gw);
  }

  &__help {
    margin: calc(var(--gw) * 2) var(--gw) var(--gw);
    padding: 0 var(--gw);
    color: var(--grey-900);
    ._text\:xs;
  }
}

// tokeny-ui namespace overwites
.split-tag__right {
  margin-left: 0 !important;
}

.help-error {
  padding: 0 !important;
}

/* Requests view */

.requests__tabs {
  background-color: var(--grey-200);
  border-bottom: 1px solid var(--grey-500);
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0 1rem;

  ds-tabs {
    button {
      border-bottom: 0px !important;
    }

    .tab--warning {
      border-bottom: 2px solid var(--orange-500) !important;
      * {
        font-weight: 500;
        color: var(--orange-600);
        fill: var(--orange-600);
      }
      &:hover * {
        color: var(--orange-600) !important;
        fill: var(--orange-600) !important;
      }
    }

    .tab--neutral {
      border-bottom: 2px solid var(--black) !important;
      * {
        font-weight: 500;
        color: var(--black);
        fill: var(--black);
      }
    }
  }
}

ds-tabs ds-tab a {
  display: block;
}

#WEB3_CONNECT_MODAL_ID {
  z-index: 9999;
  position: relative;
}

// add space to make select menu visible at the bottom of the dialog
// also need space at the top of the dialog to have some room and not interfere with top bar
ds-dialog .dialog__container {
  margin-bottom: calc(var(--gw) * 6);
  margin-top: 5rem;
}

#transactionsView {
  ds-table .filter-container .help-error {
    display: none;
  }
}

// fixes for styling overridden unintentionally
ds-token-subscription {
  .sub-summary,
  ds-select[name='paymentMethod'],
  ds-input-bignumber,
  .sub-main-order > ds-tag {
    width: 100%;
  }
  header {
    width: -moz-fit-content;
    width: fit-content;
    background: inherit;
    img {
      max-height: inherit;
    }
  }
  h3 {
    ._text\:xl;
    margin-top: 1rem;
  }
}

.dialog__container {
  ds-tag {
    .icon {
      display: flex;
    }
  }
}

.calendar {
  background-color: var(--grey-100);
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid var(--grey-400);
  padding: 0.75rem;
  gap: 0.75rem;
  align-self: stretch;
  align-items: flex-start;
  margin-bottom: 0.75rem;
  .row {
    display: flex;
    align-items: center;
    p {
      display: flex;
      align-items: center;
    }
    p:nth-child(1) {
      flex: 1.7;
    }
    p:nth-child(2) {
      flex: 2;
    }
  }
}

//Remove dark background overlay. Remove this after removing tokeny-ui legacy classes
.auth-container__background::after {
  opacity: 0;
}

// table view

.table-view {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: calc(var(--gw) * 1.5) calc(var(--gw) * 2);
  margin-top: calc(var(--gw) * 1.5);
  margin-bottom: calc(var(--gw) * 1.5);
  background: white;
  width: calc(100% - 15rem);
  height: calc(100vh - 13rem);
  border-radius: 6px;

  .xs-only({
    width: calc(100% - 1rem);
    padding:  calc(var(--gw) * 1.5) calc(var(--gw) * 1.2);
  });
  .sm-only({
    width: calc(100% - 1rem);
    padding:  calc(var(--gw) * 1.5) calc(var(--gw) * 1.2);
  });
  .md-only({
    width: calc(100% - 2rem);
    padding:  calc(var(--gw) * 1.5) calc(var(--gw) * 1.5);
  });

  ds-table {
    flex: 1;
    height: calc(100% - 40px);
  }
}

#dashboard-documents {
  h2 {
    .h5;
    ._m\:bottom\(3\);
  }
  a {
    color: var(--coolGrey-700);
    text-decoration: none;

    > div {
      border: 1px SOLID var(--grey-500);
      border-radius: 0.25rem;
      ._p\:x\(2\);
      ._p\:y\(1\);
      ._m\:bottom\(2\);
      ._flex\:justify\(space-between\);

      > div {
        ._text\:sm;
        ._m\(2\);
        ._flex\:center;

        > ds-icon {
          ._m\:right\(2\);
        }
      }

      > ds-icon {
        ._flex\:center;
        ._m\:right\(2\);
      }
    }
  }

  a:hover > div {
    background-color: var(--grey-200);
  }
}

ds-dialog#unverifiedWallet {
  margin-top: 3rem;
  z-index: 10;
  .dialog__container {
    max-width: 32rem;
  }

  h1 {
    font-size: 130%;
  }

  p {
    color: var(--coolGrey-600);
  }

  .error {
    color: var(--danger);
  }
}
